import React, { useEffect, useRef } from 'react'
import { Link } from "gatsby"
import { useDavidVids } from '../hooks/allDavidVids'
import { useDavidVTT } from '../hooks/allDavidVTT'
import { useBioTexts } from '../hooks/allBioTexts'
import { useContactInfo } from '../hooks/allContactInfo'
import { INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const DavidVidController = props => {
    const player = useRef(null)
    const allVids = useDavidVids()
    const allVTT = useDavidVTT()
    const allBioTexts = useBioTexts()
    const allContactInfo = useContactInfo()

    const positionTitles = allVids.filter(v => v.title.includes("pos") && v.mimeType.includes("mp4")).map(v => v.title)

    const positionLabels = {
        "pos1" : "Right Forehead",
        "pos2" : "Left Forehead",
        "pos3" : "Right Ear",
        "pos4" : "Right Eye",
        "pos5" : "Left Eye",
        "pos6" : "Left Ear",
        "pos7" : "Right Nostril",
        "pos8" : "Left Nostril",
        "pos9" : "Right Chin",
        "pos10" : "Left Chin",
        "pos11" : "Chest"
    }

    const richTextOptions = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} target="_blank" rel="noreferrer">{children}</a>
                )
            }
        }
    }

    const openingVideo = allVids.filter(vid => vid.title === "open")

    // React's hook alternative to "componentDidMount"
    useEffect(() => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const Plyr = require('plyr')
            const initialPlyrConfig = {
                "autoplay" : false,
                "clickToPlay" : false,
                "fullscreen" : {
                    "enabled": false,
                    "fallback": false,
                    "iosNative": false,
                    "container": "#davidVidContainer"
                }
            }

            let playerEl = document.querySelector('video#player')
            player.current = new Plyr(playerEl, initialPlyrConfig)

            /* cache according to detection of specific browser ability
            https://cconcolato.github.io/media-mime-support/?#avc_codecs */

            if (!playerEl.canPlayType('video/mp4; codecs="avc1.640028, mp4a.40.2"') === "probably" || !playerEl.canPlayType('video/webm; codecs="vp9.0, opus"') === "probably") {
                window.location.href = "/work/"
            }

            registerServiceWorker()

            const handleInit = (e) => {
                player.current.muted = true
                handleIntro()
                player.current.off('ready', handleInit)
            }

            player.current.on('ready', handleInit)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allVids])

    // Register Service Worker
    const registerServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                await navigator.serviceWorker.register(
                    '/sw.js',
                    { scope: '/' }
                )
            } catch (error) {
                console.error(`Registration failed with ${error}`)
            }
        }
    }
    
    // User Presses Enter, start video (with sound!)
    const handleIntro =  (e) => {
        player.current.on('timeupdate', handleDavidSnaps)
        player.current.on('ended', loadRandomVideo)

        let containerEl = document.querySelector('div#davidVidContainer')
        containerEl.classList.add("transition-bg-enabled")

        // player.current.muted = false
        player.current.play().catch(error => {
            player.current.restart()
        })
    }

    // Initial video has begun playing and we turn on controls when David snaps
    const handleDavidSnaps = () => {
        let time = player.current.currentTime

        if (time > 1.9) {
            player.current.off('timeupdate', handleDavidSnaps)
            
            let menu = document.getElementById('menu')
            menu.classList.add("fadein")

            let name = document.querySelector('h1.myname')
            name.classList.add("fadein")

            turnOnBodyButtons()
        }
    }

    const turnOnBodyButtons = () => {
        const bodyButtons = document.querySelectorAll('button.body')
        const bodyButtonContainer = document.getElementById('bodyButtonContainer')

        bodyButtons.forEach(button => {
            button.classList.add('on')
            button.addEventListener('click', handleBodyClicked)
        })

        bodyButtonContainer.classList.add('on')
    }

    const turnOffBodyButtons = () => {
        const bodyButtons = document.querySelectorAll('button.body')
        const bodyButtonContainer = document.getElementById('bodyButtonContainer')

        bodyButtons.forEach(button => {
            button.classList.remove('on')
            button.removeEventListener('click', handleBodyClicked)
        })

        bodyButtonContainer.classList.remove('on')
    }

    // Load the corresponding video based on clicking a part of David's body
    const handleBodyClicked = (e) => {
        let video = null
        if (e.target.id === "pos11") {
            const allGlasses = allVids.filter(v => v.title.includes("glass"))
            const randomTitle = allGlasses[Math.floor(Math.random()*allGlasses.length)].title
            
            video = allGlasses.filter(v => v.title === randomTitle)

        } else {
            video = allVids.filter(v => v.title === e.target.id)
        }

        loadNextVideo(video)
    }

    const handleBioClicked = (e) => {
        if (!bioIsOn()) {
            handleBioStart()
        } else {
            handleBioEnd(true)
        }
    }

    const bioIsOn = () => {
        return document.getElementById('bio-trigger').classList.contains('on')
    }

    const handleBioStart = () => {
        if ( contactIsOn() ) { handleContactDown() }
        document.getElementById('bio-trigger').classList.add('on')
        turnOffBodyButtons()
        loadBioVideo()

        player.current.on('timeupdate', handleBioTextTransitions)
        player.current.on('ended', handleBioEnd)
    }

    const handleBioTextTransitions = () => {
        let time = player.current.currentTime

        const div1 = document.getElementById('biotext1')
        const div2 = document.getElementById('biotext2')
        const div3 = document.getElementById('biotext3')
        const div4 = document.getElementById('biotext4')
        const div5 = document.getElementById('biotext5')
        const muteButton = document.getElementById('mute')

        if (time > 2.7 && time < 3) {
            unMute()
            div1.classList.add('fadein')
            muteButton.classList.add('fadein')
        }

        if (time > 22.5 && time < 23) { div1.classList.remove('fadein') }
        if (time > 23 && time < 24) { div2.classList.add('fadein') }

        if (time > 33.2 && time < 33.7) { div2.classList.remove('fadein') }
        if (time > 33.7 && time < 34.7) { div3.classList.add('fadein') }

        if (time > 40 && time < 40.5) { div3.classList.remove('fadein') }
        if (time > 40.5 && time < 41.5) { div4.classList.add('fadein') }

        if (time > 53 && time < 53.5) { div4.classList.remove('fadein') }
        if (time > 53.5 && time < 54.5) { div5.classList.add('fadein') }

        if (time > 63) {
            fadeOutBioElements()
        }
    }

    const fadeOutBioElements = () => {
        Array.from(document.getElementsByClassName('biotext')).forEach(el => el.classList.remove('fadein'))
        document.getElementById('mute').classList.remove('fadein')
    }

    const handleBioEnd = (withInterruption) => {
        if (withInterruption) { player.current.currentTime = 63.2 }

        fadeOutBioElements()
        document.getElementById('bio-trigger').classList.remove('on')
        player.current.off('timeupdate', handleBioTextTransitions)
        player.current.off('ended', handleBioEnd)
        turnOnBodyButtons()
    }

    const setMuteButton = () => {
        const muteButton = document.getElementById('mute')
        const muted = player.current.muted

        if (muted) {
            muteButton.classList.add('on')
            muteButton.innerHTML = "what, you don't like accents?"
        } else {
            muteButton.classList.remove('on')
            muteButton.innerHTML = "Mute Me"
        }
    }

    const toggleMute = () => {
        player.current.muted = !player.current.muted
        setMuteButton()
    }

    const unMute = () => {
        player.current.muted = false
        setMuteButton()
    }

    const handleContactClicked = (e) => {
        if ( !contactIsOn() ) {
            handleContactUp()
        } else {
            handleContactDown()
        }
    }

    const contactIsOn = () => {
        return document.getElementById('contact-trigger').classList.contains('on')
    }

    const handleContactUp = () => {
        if ( bioIsOn() ) { handleBioEnd(true) }

        document.getElementById('contact-trigger').classList.add('on')
        document.getElementById('contact-img').classList.add('fadein')
    }

    const handleContactDown = () => {
        document.getElementById('contact-trigger').classList.remove('on')
        document.getElementById('contact-img').classList.remove('fadein')
    }

    const loadBioVideo = () => {
        const bioVideo = allVids.filter(v => v.title === "bio")
        loadNextVideo(bioVideo)
    }

    // Load a random video whenever David's video ends
    const loadRandomVideo = () => {
        const randoms = allVids.filter(v => v.title.includes("rand"))
        const randomTitle = randoms[Math.floor(Math.random()*randoms.length)].title
        const random = allVids.filter(v => v.title === randomTitle)

        loadNextVideo(random)
    }

    // Generic Load and Play of video, nextVideo must be an array of precisely 1 video, 2 HTML5 sources + VTT File
    const loadNextVideo = (nextVideo) => {
        player.current.source = {
            type: 'video',
            sources: [
                {
                    src: mp4Url(nextVideo),
                    type: 'video/mp4'
                },
                {
                    src: webmUrl(nextVideo),
                    type: 'video/webm'
                }
            ],
            tracks: [
                {
                    kind: 'captions',
                    label: 'Textual description',
                    srcLang: 'en',
                    src: vttUrl(nextVideo, allVTT),
                },
                {
                    kind: 'descriptions',
                    label: 'Textual description',
                    srcLang: 'en',
                    src: vttUrl(nextVideo, allVTT),
                }
            ]
        }

        player.current.play().catch(error => {
            player.current.restart()
        })
    }

    const contactInfo = () => {
        const contents = allContactInfo.map((node) => {
            return (
                <React.Fragment key={node.id}>
                    <h1>{node.section}</h1>
                    <div className="contact-info">{renderRichText(node.info, richTextOptions)}</div>
                </React.Fragment>
            )
        })

        return (
            <div className="contact-container">
                <div className="contact-infos">{contents}</div>
            </div>
        )
    }

    return (
        <div id="davidVidController">

            <h1 className="main myname hide">david adam roth</h1>

            <ul id="menu" className="hide">
                <li><Link to="/work">Work</Link></li>
                <li><button onClick={handleBioClicked} id="bio-trigger">Autobiography</button></li>
                <li><button onClick={handleContactClicked} id="contact-trigger">Contact</button></li>
            </ul>

            <div id="bioArea">
                <button onClick={toggleMute} id="mute" className="hide">Mute Me</button>
                {allBioTexts.map((obj, index) => {
                    return <div className='biotext' id={"biotext" + (index + 1)} key={obj.id}>{renderRichText(obj.bioText, richTextOptions)}</div>
                })}
            </div>

            <div id="bodyButtonContainer">
                {positionTitles.map(title => {
                    return <button className='body' id={title} key={title} aria-label={positionLabels[title]}></button>
                })}
                <button className='body' id="pos11" aria-label={positionLabels.pos11}></button>
            </div>

            <div id="davidVidContainer">
                <video id="player" playsInline data-poster="/poster.jpg" crossOrigin="anonymous">
                    <source src={mp4Url(openingVideo)} type="video/mp4" />
                    <source src={webmUrl(openingVideo)} type="video/webm" />
                    <track kind="captions" src={vttUrl(openingVideo, allVTT)}  srcLang="en" label="Textual description" />
                    <track kind="descriptions" src={vttUrl(openingVideo, allVTT)}  srcLang="en" label="Textual description" />
                </video>
            </div>

            <div id="contact-img" className="hide">
                <img src="/contact-blank.jpg" alt="Contact Me" />
                {contactInfo()}
            </div>
        </div>
    )
}

export default DavidVidController

function mp4Url(video) {
    let match = video.find(vid => vid.mimeType.includes("mp4"))
    return match.url
}

function webmUrl(video) {
    let match = video.find(vid => vid.mimeType.includes("webm"))
    return match.url
}

function vttUrl(video, allVTT) {
    let title = video[0].title
    let match = allVTT.find(vid => vid.title === title)
    return match.url
}