import { useStaticQuery, graphql } from "gatsby"

export const useDavidVTT = () => {
    const { allContentfulAsset } = useStaticQuery(graphql`
        query davidVTTQuery {
            allContentfulAsset(filter: {mimeType: {regex: "/vtt/"}}) {
                nodes {
                    id
                    mimeType
                    url
                    title
                }
            }
        }
    `)

    return allContentfulAsset.nodes
}