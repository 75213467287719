import { useStaticQuery, graphql } from "gatsby"

export const useBioTexts = () => {
    const { allContentfulBioText } = useStaticQuery(graphql`
        query BioTexts {
            allContentfulBioText(sort: {fields: title, order: ASC}) {
                nodes {
                    id
                    bioText {
                        raw
                    }
                }
            }
        }
    `)

    return allContentfulBioText.nodes
}