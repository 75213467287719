import { useStaticQuery, graphql } from "gatsby"

export const useContactInfo = () => {
    const { allContentfulContactInfo } = useStaticQuery(graphql`
    query ContactInfo {
        allContentfulContactInfo(sort: {fields: order, order: ASC}) {
            nodes {
              id
              section
              info {
                raw
              }
            }
          }
      }
      
    `)

    return allContentfulContactInfo.nodes
}