import * as React from "react"
import Seo from "../components/seo"
import DavidVidController from "../components/davidVidController"

const IndexPage = () => {
  return (
    <div id="index-container">
      <Seo />

      <DavidVidController />

      <div className="credits">
        <div className="inner">design by david, <a href="https://www.barnstormvfx.com/team" target="_blank" rel="noreferrer">lawson</a>, rachael, and <a href="https://www.bobbylam.com/" target="_blank" rel="noreferrer">bobby</a></div>
      </div>

    </div>
  )
}

export default IndexPage